<template>
    <div id="content" class="sites">
        <template v-if="form">
            <Form :id="siteID" @back="closeForm"></Form>
        </template>
        <template v-else>
            <div class="templates-top-bar-actions">
                <!-- COMMENTED TO DO THE RELEASE -->
                <div class="left-menu-container">
                    <Button bType="showOrHideFilters" @on-button-click="handleShowHideFiltersSidebar" />
                </div>
                <div>
                    <Button bType="create" :bCallback="addLicense" bLabel="sites.add_license"></Button>
                    <Button bType="create" :customClass="{ disabled: !hasAccess }" :bCallback="addSite" bLabel="sites.add"></Button>
                    <Button bType="more" :bCallback="showMoreOptions"></Button>
                    <div class="more-options" v-if="moreOptions">
                        <Button bType="duplicate" :bCallback="downloadExcel" customClass="btn-upload" bLabel="sites.more.export_excel"></Button>
                    </div>
                </div>
            </div>

            <div class="sites-container supervise-container" :class="{ 'with-trial': trialEndDate }">
                <!-- COMMENTED TO DO THE RELEASE -->
                <div class="license-box" v-if="licenses">
                    <div
                        class="license-available"
                        v-html="
                            $t('sites.licenses_available', {
                                s: licenses.licenses - licenses.current_licenses < 0 ? 0 : licenses.licenses - licenses.current_licenses
                            })
                        "
                    ></div>
                    <div class="separator">|</div>
                    <div class="license-assigned" v-html="$t('sites.licenses_assigned', { s: licenses.current_licenses })"></div>
                    <a :href="$t('sites.licenses_hc_link')" target="_blank" class="help-center"></a>
                </div>
                <!-- sidebar filters -->
                <div
                    class="sidebar-filters"
                    :class="{ 'empty-results': noChanges && Object.values(items).length == 0, 'hidden-filters-sidebar': filtersSidebarIsHidden },"
                >
                    <FilterSites
                        @noChanges="noChanges = true"
                        @changes="noChanges = false"
                        :isDisabled="noChanges && Object.values(items).length == 0"
                        @resetPageDatatable="top(true)"
                    ></FilterSites>
                </div>
                <!-- table -->
                <div class="data-table" :class="{ 'empty-results': noChanges && Object.values(items).length == 0 }">
                    <v-data-table
                        v-if="items"
                        v-model="selected"
                        :headers="headers"
                        fixed-header
                        :items="Object.values(items)"
                        sort-by
                        :sort-asc="true"
                        hide-default-footer
                        :page.sync="pagination.page"
                        :items-per-page="pagination.itemsPerPage"
                        @page-count="pageCount = $event"
                    >
                        <template v-slot:no-data>
                            <div class="no-data">
                                {{ $t('supervise.checklists.table_headers.no_data') }}
                            </div>
                        </template>
                        <template v-slot:body="{ items }">
                            <tbody>
                                <tr
                                    v-for="item in items"
                                    :key="item.id"
                                    @click="goToReport(item.id)"
                                    @mouseover="selectItem(item)"
                                    @mouseleave="unSelectItem()"
                                >
                                    <td>
                                        <span class>{{ item.identification }}</span>
                                    </td>
                                    <td>
                                        <span class>{{ item.name }}</span>
                                    </td>
                                    <td>
                                        <span v-if="item.state">{{ item.state }}</span>
                                        <span v-else>-</span>
                                    </td>
                                    <td>
                                        <span v-if="item.city">{{ item.city }}</span>
                                        <span v-else>-</span>
                                    </td>
                                    <td>
                                        <div v-if="item.groups.length > 0">
                                            <Tags :tags="Object.values(item.groups)" :backImage="'menu_inactive_s30.svg'" :nameTag="'name'" value="id"></Tags>
                                        </div>
                                        <div v-else>-</div>
                                    </td>
                                    <td class="row-actions">
                                        <Table-statuses
                                            :text="item.status == '1' ? $t('sites.form.active') : item.status == '-2' ? $t('sites.form.inactive') : ''"
                                            :status="item.status == '1' ? 'active' : 'inactive'"
                                        >
                                        </Table-statuses>
                                        <div class="button-actions" :class="{ hidden: item != selectedItem }">
                                            <Button bType="accessLocation" :bCallback="goToApp" :bCallbackProps="item"></Button>
                                            <Button bType="editIcon" :bCallback="goToReport" :bCallbackProps="item.id"></Button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-data-table>
                    <div id="pagination" v-if="!(noChanges && Object.values(items).length == 0)">
                        <div class="totalItems">
                            <span class="total">
                                <strong class="total-results">{{ Object.values(items).length }}</strong>
                                {{ $t('supervise.checklists.table_headers.results') }}
                            </span>
                        </div>
                        <v-pagination
                            v-model="pagination.page"
                            @input="top(false)"
                            color="#2c52c4"
                            :length="pageCount"
                            :total-visible="maxPageVisibles"
                        ></v-pagination>
                        <div class="rows-page">
                            <v-select :items="rowsPerPage" :label="nRegistros" v-model="pagination.itemsPerPage"></v-select>
                        </div>
                    </div>
                    <div class="andy-datatable-empty" v-if="noChanges && itemsLoaded && Object.values(items).length == 0">
                        <EmptyTable
                            @buttonAction="addSite"
                            :buttonText="$t('sites.add')"
                            :title="$t('empty_table.users_title')"
                            :description="$t('empty_table.locations')"
                        ></EmptyTable>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import TableStatuses from '../components/ui/TableStatuses.vue'
import i18n from '@/i18n'
import FilterSites from '@/components/domain/site/filter'
import Form from '@/components/domain/site/form'
import Tags from '@/components/ui/Tags'
import EmptyTable from '@/components/ui/EmptyTable'

export default {
    components: {
        FilterSites,
        Form,
        Tags,
        EmptyTable,
        TableStatuses
    },
    name: 'sites',
    data() {
        return {
            noChanges: false,
            selectedItem: false,
            hover: false,
            siteID: false,
            form: false,
            values: false,
            singleSelect: false,
            valueFilter: 1,
            selected: [],
            pagination: {
                page: 1,
                itemsPerPage: 20
            },
            rowsPerPage: [10, 20, 50],
            pageCount: 0,
            maxPageVisibles: 10,
            headers: [
                {
                    text: this.$t('user.id'),
                    value: 'identification',
                    align: 'start',
                    width: '100px',
                    sortable: true,
                    class: 'header-table'
                },
                {
                    text: this.$t('sites.table_headers.name'),
                    align: 'start',
                    sortable: true,
                    value: 'name',
                    width: '300px',
                    class: 'header-table'
                },
                {
                    text: this.$t('sites.table_headers.province'),
                    value: 'state',
                    width: '200px',
                    sortable: true,
                    class: 'header-table'
                },
                {
                    text: this.$t('sites.table_headers.city'),
                    value: 'city',
                    align: 'start',
                    width: '200px',
                    sortable: true,
                    class: 'header-table'
                },
                {
                    text: this.$t('sites.table_headers.territories'),
                    value: 'groups',
                    align: 'start',
                    width: '200px',
                    sortable: true,
                    sort: this.sortColumnGroup,
                    class: 'header-table'
                },
                {
                    text: this.$t('sites.table_headers.state'),
                    value: 'status',
                    align: 'start',
                    width: '125px',
                    sortable: true,
                    class: 'header-table'
                }
            ],
            filtersSidebarIsHidden: false,
            moreOptions: false
        }
    },
    computed: {
        hasAccess() {
            return this.checkPermission('/sites/add')
        },
        nRegistros() {
            var letter = this.$t('supervise.checklists.table_headers.nRegisters')
            var nRegisters = this.pagination.itemsPerPage
            letter = letter.toString().replace(' ', ' ' + nRegisters + ' ')
            return letter
        },
        pages() {
            if (this.pagination.rowsPerPage == null || this.pagination.totalItems == null) {
                return 0
            }

            return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage)
        },
        items() {
            return this.$store.getters['location/getList']()
        },
        licenses() {
            return this.$store.getters['location/getLicenses']
        },
        itemsLoaded() {
            return this.$store.getters['location/getItemsLoaded']
        },
        userRole() {
            var user = this.$store.getters['login/getLocalEmployee']
            var roles = this.$store.getters['employee/getRoles']()
            if (user && Object.keys(roles).length > 0) {
                return parseInt(roles[user.rol].level)
            }
        },
        trialEndDate() {
            return this.$store.getters['login/getTrialEndDate']
        }
    },
    methods: {
        top(cond) {
            if (cond && !this.$route.params.noChangePageDatatable) {
                this.pagination.page = 1
            }
            var container = document.querySelector('.v-data-table__wrapper')
            container.scrollTo(0, 0)
        },
        selectItem(item) {
            this.selectedItem = item
        },
        unSelectItem(item) {
            this.selectedItem = false
        },
        checkPermission(routePath) {
            var route = { id: 'prueba', path: routePath }
            return this.$store.getters['hasAccess'](route)
        },

        goToReport(id) {
            if (this.checkPermission('/sites/id/idruta')) {
                this.form = true
                this.siteID = id
            } else {
                var self = this

                self.$snackbar.open({
                    message: self.$t('sites.form.no_permission'),
                    duration: 5000
                })
            }
        },
        goToApp(location) {
            var token = localStorage.getItem('token')
            var user_id = JSON.parse(localStorage.getItem('user')).employee_id
            var url = process.env.VUE_APP_APP_URL + 'dbpreview/' + token + '/' + location.id + '/' + user_id
            window.open(url, '_blank')
        },
        closeForm() {
            this.form = false
            this.siteID = false
            this.$router.push({
                name: 'Sites',
                params: {
                    noChangePageDatatable: true
                }
            })
            this.$overlay.hide()
        },
        addSite() {
            var self = this
            const isSuperAdmin = this.$store.getters['loginUser/isSuperAdmin']

            // COMMENTED TO DO THE RELEASE
            if (!isSuperAdmin && this.licenses && this.licenses.licenses - this.licenses.current_licenses <= 0) {
                this.$popup.confirm({
                    title: this.$t('sites.form.popup_no license_title'),
                    message: this.$t('sites.form.popup_no license_text'),
                    textSave: this.$t('sites.add_license'),
                    textCancel: this.$t('user.form.in_other_moment'),
                    callSave: function () {
                        self.addLicense()
                    }
                })
            } else {
                if (this.checkPermission('/sites/add')) {
                    this.form = true
                    this.siteID = false
                } else {
                    self.$snackbar.open({
                        message: self.$t('sites.form.no_permission'),
                        duration: 5000
                    })
                }
            }
        },

        addLicense() {
            var url = this.$store.getters['login/getTrialLink']
            window.open(url, '_blank')
        },

        getRole(id) {
            var status = this.$store.getters['employee/getFilterRoles'](id)
            var result = status
            return result
        },
        load() {
            var self = this
            this.$store.dispatch('location/loadFilters', {}).then(function () {
                self.$store.dispatch('location/loadLicenses', {})
                if (self.$route.params.id) {
                    self.goToReport(self.$route.params.id)
                } else if (self.$route.name == 'AddSite') {
                    self.addSite()
                } else {
                    self.form = false
                }
            })
        },

        formatTime(value) {
            return moment(value).format('DD/MM/YYYY HH:mm') != moment('00/00/0000 00:00').format('DD/MM/YYYY HH:mm')
                ? moment(value).format('DD/MM/YYYY HH:mm')
                : '-'
        },

        sortColumnGroup(a, b) {
            var nameA = Object.values(a)[0] ? Object.values(a)[0].name : '-'
            var nameB = Object.values(b)[0] ? Object.values(b)[0].name : '-'
            if (nameA < nameB) {
                return -1
            }
            return 1
        },

        handleShowHideFiltersSidebar() {
            this.filtersSidebarIsHidden = !this.filtersSidebarIsHidden
            localStorage.setItem('templateLocationsFiltersSidebarIsHiddenValue', JSON.stringify(this.filtersSidebarIsHidden))
        },
        setFiltersSidebarIsHiddenValue() {
            const storedValue = localStorage.getItem('templateLocationsFiltersSidebarIsHiddenValue')
            this.filtersSidebarIsHidden = storedValue !== null ? JSON.parse(storedValue) : false
        },
        showMoreOptions() {
            this.moreOptions = !this.moreOptions
        },
        downloadExcel() {
            this.$overlay.loading()
            const filters = this.$store.getters['location/getFiltersActive']

            const status = filters.status ? Object.values(filters.status).map(({ id }) => id) : ''
            const city = filters.city ? Object.values(filters.city).map(({ id }) => id) : ''
            const territories = filters.territories ? Object.values(filters.territories).map(({ id }) => id) : ''
            const state = filters.state ? Object.values(filters.state).map(({ id }) => id) : ''

            this.$store
                .dispatch('location/downloadReportListExcel', {
                    identification: filters.indetification ? filters.indetification : '',
                    name: filters.name ? filters.name : '',
                    status: status,
                    start_date: filters.type_date ? filters.type_date : '',
                    city: city,
                    groups: territories,
                    state: state
                })
                .then((file) => {
                    let filename = file.split('/').pop()
                    let link = document.createElement('a')
                    link.download = filename
                    link.href = file
                    document.body.appendChild(link)
                    link.click()
                    document.body.removeChild(link)
                    this.$overlay.hide()
                })
                .catch((error) => {
                    console.error(error)
                    this.$overlay.hide()
                })
        }
    },
    watch: {
        $route(to, from) {
            this.load()
        }
    },
    created() {
        this.load()
    },
    mounted() {
        this.setFiltersSidebarIsHiddenValue()
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
#content.sites {
    overflow: hidden !important;

    .license-box {
        @include display-flex();
        @include align-items();
        @include justify-content();
        padding: 15px;
        width: auto;
        margin-bottom: 0px;
        position: absolute;
        top: 60px;
        right: 0;

        .license-available,
        .license-assigned {
            @include font-size(ml);
            font-family: $text;
            color: $color-neutral-600;
        }

        .separator {
            @include font-size(ml);
            font-family: $text-light;
            color: $color-neutral-300;
            margin: 0 12px;
        }

        .help-center {
            @include background($image: img('info_777777.svg'), $size: 25px, $position: center center);
            height: 25px;
            width: 25px;
            margin-left: 12px;
            cursor: pointer;
        }
    }

    .sites-container {
        display: flex;
        align-items: stretch;
        height: calc(100% - 100px);
        padding-top: 30px;

        .data-table {
            .v-data-table,
            .v-data-table table {
                .date {
                    display: flex;
                    flex-direction: column;
                    .hour {
                        @include font-size($size: sm);
                        font-family: $text-bold;
                    }
                }
                .status {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: start;
                    align-items: center;
                }
                .employee-img {
                    @include background($size: cover);
                    display: inline-block;
                    width: 24px;
                    height: 24px;
                    border-radius: 50%;
                }
                .employee-name {
                    @include text-ellipsis();
                    display: inline-block;
                    width: calc(100% - 24px);
                    padding-left: 6px;
                    color: $color-black;

                    &.pending {
                        color: $color-warning-800;
                    }
                }
                .ball-color {
                    display: inline-block;
                    width: 9px;
                    height: 9px;
                    border-radius: 50%;
                    margin-right: 6px;
                }
                .locations {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                }
                .row-actions {
                    position: relative;
                    .button-actions {
                        @include display-flex();
                        @include align-items();
                        @include justify-content();
                        position: absolute;
                        right: 9px;
                        top: calc(50% - 15px);
                        &.hidden {
                            opacity: 0;
                        }
                    }
                }
            }
        }
        &.with-trial {
            height: calc(100% - 20px - 55px);
        }
    }
}
</style>

<style lang="scss" scoped>
#content.sites {
    .more-options {
        @include box-shadow($h: 0px, $v: 0px, $b: 10px, $s: 0px, $c: rgba($color-neutral-600, 0.3));
        @include border-radius(4px);
        @include display-flex();
        @include flex-direction(column);
        @include align-items(flex-end);
        position: absolute;
        right: 15px;
        top: 60px;
        z-index: $z-barActionMenus;
        padding: 3px 3px 0 3px;
        background-color: $color-neutral-200;

        .btn {
            box-shadow: unset;
            width: 100%;
            margin: 0 0 3px 0;
        }
    }
}
</style>
