<template>
    <div class="filter-box" :class="{ 'empty-results': isDisabled }">
        <div class="title">{{ $t('assets.filter.filter_data') }}</div>
        <div class="filter-items">
            <!-- TYPE ID -->
            <div class="filter">
                <label>{{ $t('user.id') }}</label>
                <input
                    :class="{
                        filtered: currentFilters.identification && currentFilters.identification.length > 0,
                        changed: currentFilters.identification && currentFilters.identification != activeFilters.identification
                    }"
                    type="text"
                    hide-details
                    class="input"
                    v-model="currentFilters.identification"
                    @keyup.enter="applyFilters()"
                />
            </div>

            <!-- TYPE NAME -->
            <div class="filter">
                <label>{{ $t('sites.table_headers.name') }}</label>
                <input
                    :class="{
                        filtered: currentFilters.name && Object.values(currentFilters.name).length > 0,
                        changed: currentFilters.name && currentFilters.name != activeFilters.name
                    }"
                    type="text"
                    hide-details
                    class="input"
                    v-model="currentFilters.name"
                    @keyup.enter="applyFilters()"
                />
            </div>

            <!-- TYPE STATE -->
            <div class="filter">
                <label>{{ $t('sites.table_headers.province') }}</label>
                <v-autocomplete
                    :class="{
                        filtered: currentFilters.state && Object.values(currentFilters.state).length > 0,
                        changed: JSON.stringify(currentFilters.state) != JSON.stringify(activeFilters.state)
                    }"
                    hide-details
                    class="select"
                    v-model="currentFilters.state"
                    :items="Object.values(optionsProvince)"
                    item-text="name"
                    item-value="id"
                    multiple
                    dense
                    :no-data-text="$t('supervise.filter.no_results')"
                    :placeholder="$t('assets.filter.filter_by')"
                    return-object
                >
                    <template v-slot:selection="{ item, index }">
                        {{ index == 0 ? '' : '' }}
                    </template>
                </v-autocomplete>
                <Tags :tags="currentFilters.state" :nameTag="'name'" value="id" :removable="true"></Tags>
            </div>

            <!-- TYPE CITY -->
            <div class="filter">
                <label>{{ $t('sites.table_headers.city') }}</label>
                <v-autocomplete
                    :class="{
                        filtered: currentFilters.city && Object.values(currentFilters.city).length > 0,
                        changed: JSON.stringify(currentFilters.city) != JSON.stringify(activeFilters.city)
                    }"
                    hide-details
                    class="select"
                    v-model="currentFilters.city"
                    :items="Object.values(optionsCity)"
                    item-text="name"
                    item-value="id"
                    multiple
                    dense
                    :no-data-text="$t('supervise.filter.no_results')"
                    :placeholder="$t('assets.filter.filter_by')"
                    return-object
                >
                    <template v-slot:selection="{ item, index }">
                        {{ index == 0 ? '' : '' }}
                    </template>
                </v-autocomplete>
                <Tags :tags="currentFilters.city" :nameTag="'name'" value="id" :removable="true"></Tags>
            </div>

            <!-- TYPE TERRITORIES -->
            <div class="filter">
                <label>{{ $t('sites.table_headers.territories') }}</label>
                <v-autocomplete
                    :class="{
                        filtered: currentFilters.territories && Object.values(currentFilters.territories).length > 0,
                        changed: JSON.stringify(currentFilters.territories) != JSON.stringify(activeFilters.territories)
                    }"
                    hide-details
                    class="select"
                    v-model="currentFilters.territories"
                    :items="Object.values(optionsGroups)"
                    item-text="name"
                    item-value="id"
                    multiple
                    dense
                    :no-data-text="$t('supervise.filter.no_results')"
                    :placeholder="$t('assets.filter.filter_by')"
                    return-object
                >
                    <template v-slot:selection="{ item, index }">
                        {{ index == 0 ? '' : '' }}
                    </template>
                </v-autocomplete>
                <Tags :tags="currentFilters.territories" :nameTag="'name'" value="id" :removable="true"></Tags>
            </div>

            <!-- Email -->
            <div class="filter">
                <label>{{ $t('user.filters.email') }}</label>
                <input
                    :class="{
                        filtered: currentFilters.email && typeof currentFilters.email !== 'undefined' && Object.values(currentFilters.email).length > 0,
                        changed: currentFilters.email && currentFilters.email != activeFilters.email
                    }"
                    type="text"
                    hide-details
                    class="input"
                    v-model="currentFilters.email"
                    @keyup.enter="applyFilters()"
                />
            </div>

            <!-- TYPE STATE -->
            <div class="filter">
                <label>{{ $t('sites.table_headers.state') }}</label>
                <v-autocomplete
                    :class="{
                        filtered: currentFilters.status && Object.values(currentFilters.status).length > 0,
                        changed: JSON.stringify(currentFilters.status) != JSON.stringify(activeFilters.status)
                    }"
                    hide-details
                    class="select"
                    v-model="currentFilters.status"
                    :items="Object.values(optionsStatus)"
                    item-text="name"
                    item-value="id"
                    multiple
                    dense
                    :no-data-text="$t('supervise.filter.no_results')"
                    :placeholder="$t('assets.filter.filter_by')"
                    return-object
                >
                    <template v-slot:selection="{ item, index }">
                        {{ index == 0 ? '' : '' }}
                    </template>
                </v-autocomplete>
                <Tags :tags="currentFilters.status" :nameTag="'name'" value="id" :removable="true"></Tags>
            </div>
        </div>

        <FilterActions :showClearFiltersButton="showClearFiltersButton" :clearFiltersCallback="resetFilters" :filterCallback="applyFilters" :changesDetected="changesDetected" />
    </div>
</template>

<script>
import i18n from '@/i18n'
import Tags from '@/components/ui/Tags'
import FilterActions from '../../ui/FilterActions.vue'

export default {
    name: 'FilterSites',
    components: {
        Tags,
        FilterActions
    },
    props: {
        isDisabled: { type: Boolean, default: false }
    },
    data() {
        return {
            currentFilters: {},
            emptyFilters: {
                type_date: 'week',
                name: '',
                identification: '',
                status: [{ id: 1, name: 'tools.groups.status.active' }]
            },
            activeFilters: {},
            // Filter variables
            changesDetected: false,
            maxDate: true,
            minDate: true
        }
    },
    computed: {
        optionsStatus() {
            return this.$store.getters['location/getFilterStatus']()
        },
        optionsProvince() {
            return this.$store.getters['location/getFilterStates']()
        },
        optionsCity() {
            return this.$store.getters['location/getFilterCities']()
        },
        optionsGroups() {
            return this.$store.getters['location/getFilterGroups']()
        },
        showClearFiltersButton() {
            return JSON.stringify(this.currentFilters) != JSON.stringify(this.emptyFilters)
        }
    },
    methods: {
        refreshSearch() {
            delete this.currentFilters.locations
            delete this.currentFilters.territories
        },

        applyFilters() {
            this.$overlay.loading()
            var definitiveFilters = {}

            if (this.currentFilters.identification) {
                definitiveFilters.identification = this.currentFilters.identification
            } else definitiveFilters.identification = ''

            if (this.currentFilters.name) {
                definitiveFilters.name = this.currentFilters.name
            } else definitiveFilters.name = ''

            if (this.currentFilters.state && Object.values(this.currentFilters.state).length) {
                var filter = this.currentFilters.state
                definitiveFilters.state = []
                for (var index in filter) {
                    definitiveFilters.state.push(filter[index].id)
                }
                definitiveFilters.state = definitiveFilters.state.toString()
            } else definitiveFilters.state = ''

            if(this.currentFilters.email){
                definitiveFilters.email = this.currentFilters.email
            }else definitiveFilters.email = ""

            if (this.currentFilters.city) {
                var filter = this.currentFilters.city
                definitiveFilters.city = []
                for (var index in filter) {
                    definitiveFilters.city.push(filter[index].id)
                }
                definitiveFilters.city = definitiveFilters.city.toString()
            } else {
                definitiveFilters.city = ''
            }
            if (this.currentFilters.territories) {
                var filter = this.currentFilters.territories
                definitiveFilters.territories = []
                for (var index in filter) {
                    definitiveFilters.territories.push(filter[index].id)
                }
                definitiveFilters.territories = definitiveFilters.territories.toString()
            } else {
                definitiveFilters.territories = ''
            }

            definitiveFilters.groups = definitiveFilters.territories
            delete definitiveFilters.territories

            if (this.currentFilters.status) {
                var filter = this.currentFilters.status
                definitiveFilters.status = []
                for (var index in filter) {
                    definitiveFilters.status.push(filter[index].id)
                }
                definitiveFilters.status = definitiveFilters.status.toString()
            } else {
                definitiveFilters.status = ''
            }

            if (JSON.stringify(this.currentFilters) != JSON.stringify(this.emptyFilters)) {
                this.$emit('changes')
            } else {
                this.$emit('noChanges')
            }

            var self = this
            this.changesDetected = false
            this.$store.commit('location/setFiltersActive', this.currentFilters)
            this.$store.dispatch('location/loadList', definitiveFilters).then(function () {
                self.activeFilters = { ...self.currentFilters }
                self.$overlay.hide()
                self.$emit('resetPageDatatable')
            })
        },
        resetFilters() {
            var self = this
            this.$overlay.loading()
            this.currentFilters = { ...this.emptyFilters }
            this.activeFilters = { ...this.emptyFilters }
            for (var name in this.activeFilters.status) {
                var index = self.activeFilters.status[name]
                if (index.name) {
                    index.name = i18n.t(index.name)
                }
            }
            this.$store.commit('location/setFiltersActive', self.currentFilters)

            this.changesDetected = false
            this.$store.dispatch('location/loadList', { type_date: 'week', name: '', identification: '', status: 1 }).then(function () {
                self.$overlay.hide()
                self.$emit('resetPageDatatable')
            })
        }
    },
    created() {},
    mounted() {
        var translated = this.$store.getters['location/getFiltersActive']
        for (var name in translated.status) {
            var index = translated.status[name]
            if (index.name) {
                index.name = i18n.t(index.name)
            }
        }
        this.activeFilters = {
            ...translated
        }
        this.currentFilters = {
            ...translated
        }
        this.emptyFilters = {
            ...translated
        }
        this.applyFilters()
    },
    watch: {
        currentFilters: {
            handler: function (val, oldVal) {
                if (JSON.stringify(this.currentFilters) != JSON.stringify(this.emptyFilters)) {
                    this.changesDetected = true
                }
            },
            deep: true
        }
    }
}
</script>

<style lang="scss">
// Busca en assets :^)
</style>
